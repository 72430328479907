import * as React from 'react';
import { styled } from '@mui/system';
import { alpha } from '@mui/material';

import { StaticImage } from 'gatsby-plugin-image';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';

// import separator from '../images/separatorWhite.png';
import moshedKat from '../img/MoshedKatLogo.gif';

const Section = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	// backgroundColor: alpha(theme.palette.background.paper, 0.3),
	backgroundColor: '#1d1d1d',
	position: 'relative',
	overflow: 'hidden',
}));

const FrontContent = styled(Box)({
	marginTop: 'auto',
	marginBottom: '50px',
	margin: 'auto',
	// color: '#000',
	zIndex: 0,
});

const ScrollAnchor = styled(Box)({
	position: 'absolute',
});

const AboutSection = () => {
	return (
		<Section className='section' px={8}>
			<ScrollAnchor id='AboutV2' sx={{ top: -70 }} />
			<Box
				sx={{
					maxHeight: '400px',
					position: 'absolute',
					width: '100%',
					height: '100%',
					top: '0px',
					left: '0px',
					opacity: '0.3',
					img: {
						// height: '100%',
						margin: 0,
						maxWidth: 'none',
						padding: 0,
						position: 'absolute',
						right: 0,
						top: '-80px',
						// width: '100%',
						objectFit: 'cover',
					},
				}}
			>
				<img src={moshedKat} alt='ALEIGN Instructions' />
			</Box>
			<FrontContent
				sx={{ px: { xs: 0, md: 8 } }}
				maxWidth='lg'
				className='pItem pFnt'
			>
				<Box className='container' maxWidth='lg' py={4}>
					<Grid container justify='center'>
						<Grid item xs={12} md={8}>
							<Typography
								variant='h3'
								my={2}
								sx={{
									fontSize: '2.3rem',
									fontWeight: 700,
									textTransform: 'none',
									span: { fontWeight: 'normal' },
								}}
							>
								ALEiGN <span>overview</span>
							</Typography>
							{/* {<img src={separator} className=' ' alt='separator' />} */}
							<Typography variant='body1' my={2} sx={{ i: { fontWeight: 'bold' } }}>
								<strong>Pronounced 'Align',</strong> the basis of the design, and the
								underlying motivation for the naming of the product is the{' '}
								<i>
									"aligning of people to the strategies of the company, and the
									strategies of the company to the ambitions and hopes of its people."
								</i>
							</Typography>
							<Typography variant='body1' my={2}>
								Enduring theories from <b>psychology,</b> <b>anthropology,</b>{' '}
								<b>IT service management,</b> <b>organisational change,</b>{' '}
								<b>Agile delivery</b> and <b>production system engineering</b> have all
								contributed to the
								<b> ALEiGN</b> framework.
							</Typography>
							<Typography variant='body1' my={2}>
								The product requires <b>no coding or configuration,</b> it provides a
								set of<b> integrated tools</b> to describe and <b>decompose</b> any type
								of project or strategy down to its <b>executable tasks.</b>
								<Typography variant='body1' my={2}>
									<b>ALEiGN</b> integrates{' '}
									<b>planning, task management, communications, reporting </b>
									and <b>data visualisation</b> capabilities into one platform.
								</Typography>
							</Typography>
							<Typography variant='body1' my={2}>
								<b>ALEiGN</b> is a software as a service (SaaS) product that has been
								assessed to meet the <b>Information Security Manual</b> (ISM) controls
								for processing classified data up to and including <b>PROTECTED.</b>
							</Typography>

							<Typography variant='body1' my={2}>
								ALEiGN is a team of passionate, people loving, misfits who believe work
								is a part of life… <b>Make it worth it!</b>
							</Typography>
							<Typography variant='body1' my={2}>
								Wisdom, gathered through learned experience, a diverse skillset and
								solid networks is the basis of the ALEiGN team and the product they have
								created.
							</Typography>

							{/* <Stack
								direction='row'
								// justifyContent='center'
								alignItems='center'
								spacing={6}
								mt={4}
							>
								<Button
									size='lg'
									href='#'
									target='_blank'
									rel='noopener noreferrer'
									variant='aleign'
								>
									I get it, <br />
									show me the detail
								</Button>
								<Button
									size='lg'
									href=' '
									target='_blank'
									rel='noopener noreferrer'
									variant='aleign'
								>
									Interesting, <br />
									Tell me more
								</Button>
							</Stack> */}
						</Grid>
					</Grid>
				</Box>
			</FrontContent>
		</Section>
	);
};

export default AboutSection;
