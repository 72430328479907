// copy of templates/index-page.js

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Link, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { alpha } from '@mui/material';
import theme from '../theme';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import HeroSectionTheir from '../components/sections/HeroSectionTheir';
import Mantra from '../components/sections/ALEiGN-mantraV2';
import NOOBIntro from '../components/sections/NOOB-Intro';
import ALEiGNToolbox from '../components/sections/ALEiGN-Toolbox';
import AboutSection from '../components/AboutSectionV2';
import UpdateList from '../components/UpdateList';
import instructions from '../img/ALEiGN-Instructions-OPT.gif';
import signPost from '../img/ALEiGNd-signpost.jpg';

// import ProductList from '../components/ProductList';

import Productive from '../img/being-productive.mp4';

const Section = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: alpha(theme.palette.background.paper, 0.3),
}));

const FrontContent = styled(Box)({
	marginTop: 'auto',
	marginBottom: '50px',
	margin: 'auto',
	// color: '#000',
});

const SectionContent = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	zIndex: 1,
	position: 'relative',
	height: '100%',
	overflow: 'hidden',
});

const StatBox = styled(Box)(({ theme }) => ({
	border: '6px solid',
	padding: 20,
	width: 'fit-content',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	position: 'relative',
	'.filter': {
		position: 'absolute',
		backgroundColor: alpha(theme.palette.background.default, 0.6),
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		textAlign: 'center;',
		zIndex: '-1',
		transition: 'all 0.5s ease',
	},
}));

const SuccessCard = styled(Paper)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	backgroundColor: theme.palette.paper,
	color: '#333',
	padding: theme.spacing(2),
	marginBottom: theme.spacing(3),
	'MuiTypography-h3': {
		lineHeight: 1,
	},
	'.MuiTypography-h6': {
		fontWeight: 600,
		fontSize: '1rem',
		lineHeight: 1,
		color: theme.palette.primary.main,
		paddingTop: theme.spacing(1),
	},
}));

const MainContent = styled(Box)({
	// marginTop: 'auto',
	// marginBottom: '50px',
	margin: 'auto 64px 50px',
});

const FeatureOne = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: theme.palette.paper,
	padding: theme.spacing(12),
}));

const CardOne = styled(Box)(({ theme, props }) => ({
	display: 'flex',
}));

const ScrollAnchor = styled(Box)({
	position: 'absolute',
});

// const Purple = purple[200];

// eslint-disable-next-line
export const ALEIGNintroducingTemplate = ({
	image,
	title,
	heading,
	subheading,
	mainpitch,
	description,
	main,
	intro,
	content,
	contentComponent,
}) => {
	const heroImage = getImage(image) || image;
	const PageContent = contentComponent || Content;

	return (
		<>
			<HeroSectionTheir />

			<SectionContent py={0} px={6} m={0}>
				<ScrollAnchor id='Aleignment' sx={{ top: -70 }} />
				<Box
					sx={{
						// maxHeight: '400px',
						position: 'absolute',
						width: '100%',
						height: '100%',
						top: '0px',
						left: '0px',
						backgroundColor: '#fff',
						img: {
							height: { xs: '100%', md: 'auto' },
							margin: 0,
							maxWidth: 'none',
							padding: 0,
							position: 'absolute',
							right: { xs: 0, md: '-170px' },
							top: { xs: 0, md: '-80px' },
							width: { xs: '100%', md: '60%' },
							objectFit: 'cover',
							opacity: { xs: '0.2', md: 1 },
						},
					}}
				>
					<Box
						sx={{
							background:
								'linear-gradient(to left, rgba(255,255,255,0) 30%,rgba(255,255,255,1) 49%,rgba(255,255,255,1) 100%)',
							/*background: transparent url("img/gradient.png") bottom right repeat-y;*/
							bottom: 0,
							height: '100%',
							left: 170,
							position: 'absolute',
							width: '80%',
							MozBackgroundSize: '100% 100%',
							OBackgroundSize: '100% 100%',
							WebkitBackgroundSize: '100% 100%',
							backgroundSize: '100% 100%',
							zIndex: 1,
							display: { xs: 'none', md: 'block' },
						}}
					/>
					<img src={signPost} alt='ALEIGN Instructions' />
				</Box>
				<Grid container justify='space-between' sx={{ zIndex: '1' }}>
					{/* <Grid item xs={0} md={7}></Grid> */}
					<Grid
						item
						xs={12}
						md={7}
						p={5}
						sx={{ textAlign: 'center', color: theme.palette.background.default }}
					>
						<Typography
							variant='body1'
							sx={{ fontWeight: 'inherit', pt: 4, b: { fontStyle: 'italic' } }}
						>
							If you use Trello, Jira, Monday, Slack, Clickup, Teams... <br />
							or any of the many other productivity / task / project / work management
							tools in the
							<br />
							<b> 'helping to organise your life and get things done'</b> category of
							Apps...
						</Typography>
						<Typography variant='body1' sx={{ mb: 0, pt: 1 }}>
							<b>AND</b>
							<br /> you are <b>still looking</b> for an App to
							<br /> <i>'help organise your life and get things done'</i>
						</Typography>
						<Typography
							variant='h4'
							color='primary'
							sx={{ m: 0, pt: 2, fontSize: '1.7rem' }}
						>
							A good
						</Typography>
						<Typography
							variant='h2'
							color='primary'
							sx={{
								textTransform: 'none',
								fontWeight: '700',
								fontSize: '3rem',
								// wordBreak: 'break-word',
							}}
						>
							“ALEiGN-ment”
						</Typography>
						<Typography
							variant='h4'
							color='primary'
							sx={{ mt: 0, fontSize: '1.7rem' }}
						>
							maybe just what you're looking for!
						</Typography>
						<Typography
							variant='h3'
							mb={2}
							sx={{ fontWeight: 700, textTransform: 'none' }}
							color='secondary'
						>
							#context #comms #culture #move2done!
						</Typography>
						{/* <Typography variant='body1' sx={{ fontWeight: 'inherit', pb: 4 }}>
							Leave us your details and we'll be in touch to chat about why you are
							still looking and what it is you are looking for.
						</Typography> */}
						<Stack
							direction={{ xs: 'column', sm: 'row' }}
							justifyContent='center'
							alignItems='center'
							spacing={3}
							mt={4}
							pb={4}
						>
							<Button
								variant='aleign'
								component={Link}
								to='#AboutV2'
								sx={{
									color: theme.palette.primary.main,
									'&:after, &:before': { background: theme.palette.primary.main },
									'&:hover,&:focus,&:active,&.active': {
										color: '#fff',
										backgroundColor: theme.palette.primary.main,
										borderColor: theme.palette.primary.main,
									},
								}}
							>
								Read on
							</Button>
							<Button
								variant='aleign'
								// component={Link}
								// to='#'
								onClick={() => window.FreshworksWidget('open')}
								sx={{
									color: theme.palette.primary.main,
									'&:after, &:before': { background: theme.palette.primary.main },
									'&:hover,&:focus,&:active,&.active': {
										color: '#fff',
										backgroundColor: theme.palette.primary.main,
										borderColor: theme.palette.primary.main,
									},
								}}
								rel='noopener noreferrer'
							>
								I have a question
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</SectionContent>
			<AboutSection />
			<Mantra />
			<ALEiGNToolbox />
			<UpdateList />
		</>
	);
};

ALEIGNintroducingTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	heading: PropTypes.string,
	subheading: PropTypes.string,
	mainpitch: PropTypes.object,
	description: PropTypes.string,
	intro: PropTypes.shape({
		blurbs: PropTypes.array,
	}),
};

const ALEIGNintroducing = ({ data }) => {
	const { frontmatter } = data.markdownRemark;
	const { markdownRemark: post } = data;

	return (
		<Layout>
			<ALEIGNintroducingTemplate
				image={frontmatter.image}
				title={frontmatter.title}
				heading={frontmatter.heading}
				subheading={frontmatter.subheading}
				mainpitch={frontmatter.mainpitch}
				description={frontmatter.description}
				main={frontmatter.main}
				intro={frontmatter.intro}
				contentComponent={HTMLContent}
				content={post.html}
			/>
		</Layout>
	);
};

ALEIGNintroducing.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default ALEIGNintroducing;

export const pageQuery = graphql`
	query introducingTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "aleign-theirPage" } }) {
			html
			frontmatter {
				title
				image {
					childImageSharp {
						gatsbyImageData(quality: 100, layout: FULL_WIDTH)
					}
				}
				heading
				subheading
				mainpitch {
					title
					description
				}
				description
				intro {
					blurbs {
						text
						heading
					}
					heading
					description
				}
				main {
					heading
					description
					image1 {
						alt
						image {
							childImageSharp {
								gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
							}
						}
					}
					image2 {
						alt
						image {
							childImageSharp {
								gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
							}
						}
					}
					image3 {
						alt
						image {
							childImageSharp {
								gatsbyImageData(quality: 100, layout: FULL_WIDTH)
							}
						}
					}
				}
			}
		}
	}
`;
